import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/radca/#nadcha"> &gt; Radca(NÁDCHA)</a>
            <a href="/radca/nadcha/ako_si_spravne_cistit_nos/" className="sel">
              {" "}
              &gt; Ako si správne čistiť nos pri nádche?
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="main_photo">
                <img src="/web/kcfinder/uploads/files/artykuly/shutterstock_300834140.jpg" />
              </div>
            </div>
            <div className="col2">
              <h1>Ako si správne čistiť nos pri nádche?</h1>
              <div className="wysiwyg">
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <b>
                    Zápal nosovej sliznice (ľudovo nádcha) je nepríjemné
                    ochorenie, ktoré postihuje každého z nás, a to predovšetkým
                    v jesennom a zimnom období. Vtedy sme náchylní na pôsobenie
                    vírusov, baktérií a iných mikroorganizmov. Pri nádche treba
                    pamätať na správnu hygienu nosa. Ako má vyzerať v praxi?{" "}
                  </b>
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  Správna hygiena horných dýchacích ciest je jedným z
                  najdôležitejších spôsobov boja proti prechladnutiu. Keď už sa
                  objaví nádcha, vždy treba používať jednorazové hygienické
                  vreckovky. Dôležité je nesmrkať oboma nosovými dierkami
                  súčasne, pretože vtedy sa zvyšuje tlak v nose, hlieny
                  (nakazené vírusom) prenikajú do dutín a spôsobujú infekciu uší
                  (vyvolávajú zápal). Vhodná je aj inhalácia (napr. vodnej pary
                  alebo záparu z éterických olejov), a to najmä u detí, ktoré sa
                  ešte nedokážu samé vysmrkať. Pri čistení horných dýchacích
                  ciest pomáhajú aj prípravky s obsahom morskej vody, ktoré
                  možno rozdeliť na roztoky izotonické (pomáhajú odstrániť
                  hlieny) a hypertonické (riedia zahlienenie, ktoré sa potom z
                  nosa ľahšie odstráni). Pri čistení nosa u malých detí možno
                  použiť aj odsávačku alebo tradičnú „hrušku“. Tieto metódy sú
                  najúčinnejšie, ak je výtok riedky.
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <img
                    alt="OLYNTH® metódy správneho čistenia nosa a riešenie nádchy."
                    src="/web/kcfinder/uploads/images/shutterstock_666354331.jpg"
                    style={{
                      width: "360px",
                      height: "240px",
                      float: "left",
                      margin: "5px 10px 10px 0px"
                    }}
                  />
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <div>
                  Kýchací reflex je obranný mechanizmus nášho tela, ktorý nám –
                  podobne ako proces smrkania – umožňuje zbaviť sa vírusov z
                  organizmu. Žiaľ, nie je ľahké zbaviť sa výtoku z nosových
                  otvorov, a to najmä ak sa objaví aj opuch nosovej sliznice
                  alebo hlieny zhustnú. V takom prípade je vhodné postarať sa o
                  spriechodnenie nosa príslušnými liekmi alebo prípravkami.
                  Dobrým riešením sú nosové roztokové aerodisperzie – napríklad{" "}
                  <a href="/lieky/olynth_01/" target="_blank">
                    OLYNTH<sup>®</sup> 0,1 %
                  </a>{" "}
                  alebo{" "}
                  <a href="/lieky/olynth_ha_01/" target="_blank">
                    OLYNTH<sup>®</sup> HA 0,1 %
                  </a>
                  . Obsahujú{" "}
                  <a href="/slovnik_pojmov/#xylometazolin" target="_blank">
                    xylometazolín
                  </a>
                  , ktorý obnovuje priechodnosť nosa a uľahčuje dýchanie nosom.
                </div>
                <div>&nbsp;</div>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p1"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <div>
                  Správne čistenie nosových otvorov má veľký zdravotný význam,
                  pretože nos chráni náš organizmus pred ochoreniami. Nachádzajú
                  sa v ňom riasinky filtrujúce prúdiaci vzduch, ktorý zohrievajú
                  a vychytávajú z neho nečistoty (napr. častice{" "}
                  <a href="/slovnik_pojmov/#alergen">alergénov</a>, vírusy,
                  baktérie). Tie sa potom z tela odstraňujú reflexným kýchaním.
                  Nos je veľmi citlivý na vonkajšie faktory, ktoré môžu narušiť
                  jeho správnu funkciu. Keď sme unavení alebo máme za sebou
                  prebdené noci, aj náš{" "}
                  <a href="/slovnik_pojmov/#imunitny_system">imunitný systém</a>
                  &nbsp;je oslabený, čo prospieva vzniku{" "}
                  <a href="/slovnik_pojmov/#infekcia">infekcií</a>.&nbsp;
                  <span
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    V takých prípadoch treba rýchlo rozpútať boj proti
                    nepriateľovi a použiť vhodné lieky.&nbsp;
                  </span>
                </div>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <div>
                  Nádchu často vnímame ako nepríjemnú komplikáciu, nesmieme však
                  zabúdať, že neliečená nádcha môže viesť k závažným infekciám,
                  ako sú napríklad:
                </div>
                <div>
                  zápal dutín, zápal ucha (najmä u detí), zápal priedušiek alebo
                  chronická nádcha.
                </div>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p2"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    Literatúra:&nbsp;
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    P. Dąbrowski, Zapalenie górnych dróg oddechowych, [v:]
                    „Przewodnik Lekarza”, 2002, 5, 3, s. 37–42.{" "}
                  </span>
                </p>
                <p
                  className="p3"
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    P. Rapiejko, A. Lipiec, Podział nieżytów nosa, [v:]
                    „Alergia”, 2013, nr 2, s. 6-8
                  </span>
                </p>
              </div>
              <div className="other_article">
                <p className="name">Ďalšie články</p>
                <a href="/radca/nadcha/spoznajte_typy_nadchy/" className="box">
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/shutterstock_388715146.jpg)" }} />
                  <span className="title">Spoznajte typy nádchy</span>
                </a>
                <a
                  href="/radca/nadcha/zabojujte_s_nadchou_rychlo/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_152527238.jpg)" }} />
                  <span className="title">Zabojujte s nádchou rýchlo</span>
                </a>
                <a
                  href="/radca/nadcha/alergicka_nadcha_spoznajte_ju_a_predchadzajte_jej/"
                  className="box"
                >
                  <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/shutterstock_1206612040.jpg)" }} />
                  <span className="title">
                    Alergická nádcha – spoznajte ju a predchádzajte jej
                  </span>
                </a>
              </div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}

      </div>
    );
  }
}

export default Page;
